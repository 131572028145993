export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAIL = 'REGISTER_FAIL'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT = 'LOGOUT'
export const FORGOT_SUCCESS = 'FORGOT_SUCCESS'
export const FORGOT_FAIL = 'FORGOT_FAIL'
export const RESET_SUCCESS = 'RESET_SUCCESS'
export const RESET_FAIL = 'RESET_FAIL'
export const EMAIL_VERIFICATION_SUCCESS = 'EMAIL_VERIFICATION_SUCCESS'
export const EMAIL_VERIFICATION_FAIL = 'EMAIL_VERIFICATION_FAIL'
export const RELOAD_FROM_LOCALSTORAGE = 'RELOAD_FROM_LOCALSTORAGE'

export const SET_SIDEBARSHOW = 'SET_SIDEBARSHOW'
export const SET_SIDEBARUNFOLDABLE = 'SET_SIDEBARUNFOLDABLE'

export const SET_ORDERS = 'SET_ORDERS'
export const SET_ORDERS_LOADING = 'SET_ORDERS_LOADING'
export const SET_CURRENT_ORDER = 'SET_CURRENT_ORDER'
export const SET_FILTERED_ORDERS = 'SET_FILTERED_ORDERS'

export const SET_PAYMENTS = 'SET_PAYMENTS'
export const SET_PAYMENTS_LOADING = 'SET_PAYMENTS_LOADING'
