import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  FORGOT_SUCCESS,
  FORGOT_FAIL,
  RESET_SUCCESS,
  RESET_FAIL,
  EMAIL_VERIFICATION_SUCCESS,
  EMAIL_VERIFICATION_FAIL,
  RELOAD_FROM_LOCALSTORAGE,
} from '../actions/types'

const user = JSON.parse(localStorage.getItem('user'))
const token = JSON.parse(localStorage.getItem('token'))

const initialState = user
  ? { isLoggedIn: true, user, token }
  : { isLoggedIn: false, user: null, token: null }

const authReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
      }
    case REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
        token: payload.token,
      }
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        token: null,
      }
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        token: null,
      }
    case FORGOT_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
      }
    case FORGOT_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      }
    case RESET_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
      }
    case RESET_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      }
    case EMAIL_VERIFICATION_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
      }
    case EMAIL_VERIFICATION_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      }
    case RELOAD_FROM_LOCALSTORAGE:
      const user = JSON.parse(localStorage.getItem('user'))
      const token = JSON.parse(localStorage.getItem('token'))
      return user
        ? { isLoggedIn: true, user, token }
        : { isLoggedIn: false, user: null, token: null }
    default:
      return state
  }
}

export default authReducer
