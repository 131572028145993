import { combineReducers } from 'redux'
import auth from './auth'
import setting from './setting'
import order from './order'
import payment from './payment'

export default combineReducers({
  auth,
  setting,
  order,
  payment,
})
