import React, { Suspense, useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { GrowthBookProvider } from "@growthbook/growthbook-react";

import growthbook from './growthbook'

import { AUTO_CLOSE_TIME } from './views/notifications/toasts/Toast.constants'

import './scss/style.scss'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/auth/Login'))
const Register = React.lazy(() => import('./views/auth/Register'))
const ForgotPassword = React.lazy(() => import('./views/auth/ForgotPassword'))
const ResetPassword = React.lazy(() => import('./views/auth/ResetPassword'))
const EmailVerification = React.lazy(() => import('./views/auth/EmailVerification'))
const Page404 = React.lazy(() => import('./views/auth/Page404'))
const Page500 = React.lazy(() => import('./views/auth/Page500'))

const App = () => {
  useEffect(() => {
    growthbook.loadFeatures();
  }, [])

  return (
    <GrowthBookProvider growthbook={growthbook}>
      <BrowserRouter>
        <ToastContainer autoClose={AUTO_CLOSE_TIME} pauseOnFocusLoss={false} />
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/login" name="Login" element={<Login />} />
            <Route exact path="/register" name="Register" element={<Register />} />
            <Route exact path="/forgot" name="Forgot Password" element={<ForgotPassword />} />
            <Route exact path="/reset/:token" name="Reset Password" element={<ResetPassword />} />
            <Route
              exact
              path="/verification/:token"
              name="Email Verification"
              element={<EmailVerification />}
            />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </GrowthBookProvider>
  )
}


export default App
