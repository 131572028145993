import { SET_SIDEBARSHOW, SET_SIDEBARUNFOLDABLE } from '../actions/types'

const initialState = {
  sidebarShow: true,
  unfoldable: true,
}

const settingReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case SET_SIDEBARSHOW:
      return { sidebarShow: payload }

    case SET_SIDEBARUNFOLDABLE:
      return { unfoldable: payload }

    default:
      return state
  }
}

export default settingReducer
