import {
  SET_ORDERS,
  SET_ORDERS_LOADING,
  SET_CURRENT_ORDER,
  SET_FILTERED_ORDERS,
} from '../actions/types'

const initialState = {
  orders: null,
  isLoading: false,
  currentOrder: null,
  filteredOrders: null,
}

const orderReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case SET_ORDERS_LOADING:
      return {
        ...state,
        isLoading: payload,
      }
    case SET_ORDERS:
      return {
        ...state,
        orders: payload ?? [],
        filteredOrders: null,
        currentOrder: null,
      }
    case SET_CURRENT_ORDER:
      return {
        ...state,
        currentOrder: payload,
      }
    case SET_FILTERED_ORDERS:
      return {
        ...state,
        filteredOrders: payload,
      }
    default:
      return state
  }
}

export default orderReducer
