import {
  SET_PAYMENTS,
  SET_PAYMENTS_LOADING,
} from '../actions/types'

const initialState = {
  orders: null,
  isLoading: false,
}

const orderReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case SET_PAYMENTS_LOADING:
      return {
        ...state,
        isLoading: payload,
      }
    case SET_PAYMENTS:
      return {
        ...state,
        payments: payload ?? [],
      }
    default:
      return state
  }
}

export default orderReducer
